<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import quizCategorieCard from "../../../components/widgets/card/quizCategorieCard.vue";
import { Api } from "../../../helpers";
import Swal from "sweetalert2";
import { Erreur } from "../../../helpers/error";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";


export default {
	props: {},
	page: {
		title: "Blog List",
		meta: [{ name: "description", content: appConfig.description }],
	},
	components: {
		Layout,
		PageHeader,
		quizCategorieCard,
		loaderProcessVue,
	},
	data() {
		return {
			titre: "",
			progress: 0,
			process: false,
			categories: [],
			isAuthError: false,
			authError: "",
			categorieQuiz: {
				name: "",
			},
			spinner:false,
			addModal: false,
			secondModal: false,
			title: "Liste des compétitions",
			items: [
				{
					text: "Quiz",
					// href: "/",
				},
				// {
				//   text: "Articles",
				//   active: true,
				// },
			],
		};
	},
	methods: {
		showAddModal() {
			this.addModal = true
		},
		imgVideoSelect(event) {
			this.imgSelect = event.target.files[0]; // Get the selected image or video
			console.log(this.imgSelect);
		},

		imgVideoSelectPaysage(event) {
			this.imgPaysage = event.target.files[0];
			console.log(this.imgPaysage);
		},
		createAward() {



			const onUploadProgress = progressEvent => {
				this.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
				console.log(`Upload progress: ${this.progress}%`);
			}

			// if (
			// 	!this.award.description ||
			// 	!this.award.endDate ||
			// 	!this.award.name ||
			// 	!this.award.startDate ||
			// 	!this.award.votePrice ||
			// 	!this.imgPaysage ||
			// 	!this.imgSelect
			// ) {

			// 	this.isAuthError = true
			// 	this.authError = "Veuillez remplir tous les champs"
				
			// } else {

				this.process = true
				this.addModal = false
				this.titre = "Création d'une catégorie"
				Api.postFormData(`/games/rest/category/create`, {
					imagePaysage: this.imgPaysage,
					imagePortrait: this.imgSelect,
					name: this.categorieQuiz.name
				}, onUploadProgress)
					.then((response) => {
						this.process = false
						Swal.fire("Succes!", "Catégorie créée avec succès", "success");
						console.log(response);
						location.reload()
					}).catch((error) => {
						this.process = false
						console.log(error)
						Erreur.gestionErreur(error.message)
					})
			// }

		},
	},

	mounted() {
		// this.process = true
		this.titre = "Chargement des compétitions"
		this.spinner = true
		Api.get("/games/rest/category/all")
			.then((res) => {
				this.process = false
				this.categories = res.data;
				console.log(res);
				this.spinner = false
			})
			.catch((error) => {
				this.process = false
				this.spinner = false
				console.log(error);
				Erreur.gestionErreur(error.message);
			});
			
	},
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<loaderProcessVue :title="titre" :progress="progress" :visible="process"></loaderProcessVue>
		<div class="row justify-content-end">
			<div class="col-lg-10" style="margin-top: 10px">
				<div @click="showAddModal" class="btn c2play-primary" style="position: relative; left: 79%"
					to="/blog/createArticle">
					<span style="color: #fff"><i class="fas fa-plus font-size-18"></i> {{ $t("addButton") }} une categorie</span>
				</div>
			</div>
		</div>

		<br/>

		<div class="row">
			<quizCategorieCard v-for="categorie in categories.slice().reverse()" :key="categorie.id" :categorie="categorie"></quizCategorieCard>
			<!-- <quizCategorieCard></quizCategorieCard> -->
			<div
                style="text-align: center; font-size: 3em"
                v-if="spinner == true"
              >
                <b-spinner variant="success" role="status"></b-spinner>
				<span v-if="spinner == false && categories.length < 1">Aucune catégorie ajoutée</span>
              </div>
		</div>

		<b-modal v-model="addModal" id="modal-xl" size="xl" title="Nouvelle catégorie" title-class="font-18" hide-footer>
			<!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

			<div class="card-body">
				<b-alert v-model="isAuthError" variant="danger" class="mt-1" dismissible>
					{{ authError }}
				</b-alert>

				<form>
					<div class="form-group row mb-2">
						<div class="col-lg-12">
							<label for="projectname" class="col-form-label">{{
								$t("pages.videos.formulaire.titre.label")
							}}</label><span style="color: red; font-size: 1.2em">*</span>
							<input id="projectname" name="projectname" v-model="categorieQuiz.name" type="text" class="form-control"
								placeholder="Entrez le titre de la catégorie"/>
						</div>
					</div>

					<div class="row mb-2">
						<div class="form-group col-lg-6">
							<label for="projectname" class="col-form-label">{{
								$t("pages.videos.formulaire.files.image")
							}} portrait</label><span style="color: red; font-size: 1.2em">*</span>
							<div class="col-lg-12">
								<input id="projectname" name="projectname" type="file" @change="imgVideoSelect($event)"
									class="form-control" placeholder="Entre le lien de la vidéo..."
									accept=".png, .jpeg, .jpg" />
							</div>
						</div>

						<div class="form-group col-lg-6">
							<label for="projectname" class="col-form-label">Image paysage</label><span
								style="color: red; font-size: 1.2em">*</span>
							<div class="col-lg-12">
								<input id="projectname" name="projectname" type="file"
									@change="imgVideoSelectPaysage($event)" class="form-control"
									placeholder="Entre le lien de la vidéo..." accept=".png, .jpeg, .jpg" />
							</div>
						</div>
					</div>
				</form>

				<div class="row">
					<div class="col-lg-12" style="margin-top: 10px">
						<button class="btn c2play-primary" @click="createAward" style="position: relative; left: 45%">
							Créer
						</button>
					</div>
				</div>
			</div>
		</b-modal>

	</Layout>
</template>