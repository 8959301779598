<template>
  <div class="col-lg-3">
    <!-- Simple card -->
    <b-card style="border-radius: 13px">
      <router-link
        :to="`/games/addQuiz/${this.categorie.id}`"
        style="color: #303030"
      >
        <div>
          <img
            class="card-img"
            :src="`${this.categorie.urlImagePortrait}`"
            alt="Card image"
            style="object-fit: cover; opacity: 0.9"
          />
        </div>

        <b-card-title>
          <h5
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              text-align: center;
            "
          >
            {{ categorie.name }}
          </h5>
        </b-card-title>
        <b-card-text>
          <h4 style="text-align: center">
            {{ time }}
          </h4>
        </b-card-text>
      </router-link>
      <div style="display: flex; align-items: center; justify-content: center">
        <b-button class="c2play-primary">
          <b-dropdown
            class="card-drop"
            variant="white"
            menu-class="dropdown-menu-end"
            right
            toggle-class="p-0"
          >
            <template v-slot:button-content>
              <span style="color: #fff"
                ><i class="mdi mdi-dots-horizontal font-size-18"></i
              ></span>
            </template>
            <b-dropdown-item @click="showUpdateCat()">{{
              $t("pages.videos.actions.modifier")
            }}</b-dropdown-item>
            <b-dropdown-item @click="confirm()">{{
              $t("pages.videos.actions.supprimer")
            }}</b-dropdown-item>
          </b-dropdown>
        </b-button>
      </div>
    </b-card>

    <b-modal
      v-model="modalUpdateCat"
      id="modal-xl"
      size="xl"
      title="Modifier la catégorie"
      title-class="font-18"
      hide-footer
    >
      <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>

        <form>
          <div class="form-group row mb-2">
            <div class="col-lg-12">
              <label for="projectname" class="col-form-label">{{
                $t("pages.videos.formulaire.titre.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <input
                id="projectname"
                name="projectname"
                v-model="name"
                type="text"
                class="form-control"
                :placeholder="$t('pages.videos.formulaire.titre.placeholder')"
              />
            </div>
          </div>

          <div class="row mb-2">
            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >{{ $t("pages.videos.formulaire.files.image") }} portrait</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="file"
                  @change="imgVideoSelect($event)"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectname" class="col-form-label"
                >Image paysage</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="file"
                  @change="imgVideoSelectPaysage($event)"
                  class="form-control"
                  placeholder="Entre le lien de la vidéo..."
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>
          </div>
        </form>

        <div class="row">
          <div class="col-lg-12" style="margin-top: 10px">
            <button
              class="btn c2play-primary"
              @click="updateCategorie"
              style="position: relative; left: 45%"
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <loaderProcessVue
      :title="titre"
      :progress="progress"
      :visible="process"
    ></loaderProcessVue>
  </div>
</template>

<script>
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";
import Swal from "sweetalert2";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
export default {
  components: {
    loaderProcessVue,
  },
  data() {
    return {
      titre: "",
      progress: 0,
      process: false,
      name: "",
      modalUpdateCat: false,
    };
  },
  props: {
    categorie: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showUpdateCat() {
      this.modalUpdateCat = true;
    },

    imgVideoSelect(event) {
      this.imgSelect = event.target.files[0]; // Get the selected image or video
      console.log(this.imgSelect);
    },

    imgVideoSelectPaysage(event) {
      this.imgPaysage = event.target.files[0];
      console.log(this.imgPaysage);
    },

    updateCategorie() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      this.process = true;
      this.modalUpdateCat = false;
      this.titre = "Modification de la catégorie";
      Api.putFormData(
        `/games/rest/category/update/${this.categorie.id}`,
        {
          imagePaysage: this.imgPaysage,
          imagePortrait: this.imgSelect,
          name: this.name,
        },
        {onUploadProgress}
      )
        .then((response) => {
          this.process = false;
          Swal.fire("Succes!", "Catégorie modifiée avec succès", "success");
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
    confirm() {
      Swal.fire({
        title: "Voulez-vous vraiment supprimer cette catégorie ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteCategorie();
          // location.reload();
        }
      });
    },
    deleteCategorie() {
      Api.delete(`/games/rest/category/delete/${this.categorie.id}`)
        .then((response) => {
          console.log(response);
          Swal.fire("Deleted!", "Catégorie supprimée avec succes", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    this.name = this.categorie.name;
  },
};
</script>

<style>
.card-img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 9px;
  margin-bottom: 20px;
}
</style>
